import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './sass/main.scss'
import './registerServiceWorker'
import titleMixin from './mixins/title'
import VueAnalytics from 'vue-analytics';

Vue.config.productionTip = true;


// Register mixins.
Vue.mixin(titleMixin)


//Google Analytics
Vue.use(VueAnalytics, {
  id: 'UA-192733801-1',
  router
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

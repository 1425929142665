<template>
	<div v-if="enabled">
		<loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"></loading>
		<div class="page--wrapper" v-bind:style="{ 'background-image': 'url(' + page.backgroundUrl + ')',}"></div>
		<div class="page--wrapper-contest" v-if="enabled">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-12"></div>

					<div class="col-lg-6 col-12">
						<div class="page--block">
							<div class="page--block-header" v-bind:style="{ 'background-image': 'url(' + page.backgroundUrl + ')',}">
								<div class="page--block-header-logo" v-bind:style="{ 'background-image': 'url(' + page.logoUrl + ')',}"></div>
							</div>
							<div class="page--block-content">
								<div class="page--block-title" v-bind:style="{color: page.primaryColor,}">{{page.title}}</div>
								<p class="page--block-description" v-bind:style="{color: page.textColor,}" v-html="page.description.replace(new RegExp('\r?\n','g'), '<br />')">
								</p>
								<strong class="page--block-description page--block-description-head " v-bind:style="{color: page.secondaryColor,}">
									Track Details
								</strong>
								<p class="page--block-description" v-bind:style="{color: page.textColor,}">
									{{ page.trackArtist }} - {{ page.trackName }}
								</p>

								<div class="page--block-button" @click="downloadStems" v-bind:style="{backgroundColor: page.primaryColor}">Download Stems</div>

								<a :href="page.trackLink">
									<div class="page--block-button" v-bind:style="{backgroundColor: page.secondaryColor}">Listen Track</div>
								</a>
							</div>
						</div>
						<div class="countdown--block" v-if="!contestIsOpen && contestEnded == false">
							<div class="container">
								<div class="row">
									<div class="col-2">
										<svg version="1.1" class="countdown--lock" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
											<g>
												<g>
													<path d="M437.333,192h-32v-42.667C405.333,66.99,338.344,0,256,0S106.667,66.99,106.667,149.333V192h-32
										C68.771,192,64,196.771,64,202.667v266.667C64,492.865,83.135,512,106.667,512h298.667C428.865,512,448,492.865,448,469.333
										V202.667C448,196.771,443.229,192,437.333,192z M287.938,414.823c0.333,3.01-0.635,6.031-2.656,8.292
										c-2.021,2.26-4.917,3.552-7.948,3.552h-42.667c-3.031,0-5.927-1.292-7.948-3.552c-2.021-2.26-2.99-5.281-2.656-8.292l6.729-60.51
										c-10.927-7.948-17.458-20.521-17.458-34.313c0-23.531,19.135-42.667,42.667-42.667s42.667,19.135,42.667,42.667
										c0,13.792-6.531,26.365-17.458,34.313L287.938,414.823z M341.333,192H170.667v-42.667C170.667,102.281,208.948,64,256,64
										s85.333,38.281,85.333,85.333V192z" />
												</g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
											<g>
											</g>
										</svg>
									</div>
									<div class="col-10">
										<div class="container-fluid">
											<div class="row">
												<div class="col-3">
													<span class="countdown--int">{{openingIn.d}}</span>
													<span class="countdown--label">Days</span>
												</div>
												<div class="col-3">
													<span class="countdown--int">{{openingIn.h}}</span>
													<span class="countdown--label">Hours</span>
												</div>
												<div class="col-3">
													<span class="countdown--int">{{openingIn.m}}</span>
													<span class="countdown--label">Minutes</span>
												</div>
												<div class="col-3">
													<span class="countdown--int">{{openingIn.s}}</span>
													<span class="countdown--label">Seconds</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="countdown--block" v-if="submitted == true"><strong>Thanks, your demo is submitted!</strong></div>
						<div class="form--block" v-if="contestIsOpen && !submitted">
							<div class="container-fluid container--no-margin" v-bind:class="{ 'page--not-open': !contestIsOpen }">
								<div class="page--form-content">
									<div class="row row--spacing">
										<div class="col-xs-12 col-sm-12 col-lg-12">
											<div class="page--input-label">Artist Name</div>
											<input type="text" placeholder="Artist" name="title" v-model="demo.artist_name" class="input--text email login--input-bottom" />
										</div>
									</div>
									<div class="row row--spacing">
										<div class="col-xs-12 col-sm-12 col-lg-12">
											<div class="page--input-label">Email</div>
											<input type="text" placeholder="E-mail Address" name="" v-model="demo.artist_email" class="input--text email login--input-bottom" />
										</div>
									</div>
									<hr />
									<div class="row">
										<div class="col-xs-12 col-sm-12 col-lg-12">
											<div class="page--input-label">How would you like to deliver your demo?</div>
											<select class="input--select" v-model="type">
												<option value="soundcloud">Soundcloud</option>
												<option value="dropbox">Dropbox</option>
												<option value="file">MP3 / WAV</option>
											</select>
										</div>
									</div>
									<hr />
									<div class="row row--spacing" v-if="type == 'soundcloud'">
										<div class="col-xs-12 col-sm-12 col-lg-12">
											<div class="page--input-label">Soundcloud URL</div>
											<input type="text" v-model="demo.soundcloud_url" placeholder="https://soundcloud.com/user/trackname" name="" class="input--text email login--input-bottom" />
										</div>
									</div>
									<div class="row row--spacing" v-if="type == 'dropbox'">
										<div class="col-xs-12 col-sm-12 col-lg-12">
											<div class="page--input-label">Dropbox URL</div>
											<input type="text" v-model="demo.dropbox_url" placeholder="Dropbox URL" name="" class="input--text email login--input-bottom" />
										</div>
									</div>
									<div class="row row--spacing" v-if="type == 'file'">
										<div class="col-xs-12 col-sm-12 col-lg-12">
											<div id="drop_zone" v-if="!files[0]" class="input--drop" v-cloak @drop.prevent="addFile" @dragover.prevent>
												<p class="input--drop-text"><strong>Drag </strong> a file here..</p>
												<svg version="1.1" class="input--drop-icon" viewBox="0 0 408 408" style="enable-background:new 0 0 408 408;" width="75" xml:space="preserve">
													<g>
														<g>
															<path d="M332,121.921H184.8l-29.28-34.8c-0.985-1.184-2.461-1.848-4-1.8H32.76C14.628,85.453,0,100.189,0,118.321v214.04
c0.022,18.194,14.766,32.938,32.96,32.96H332c18.194-0.022,32.938-14.766,32.96-32.96v-177.48
C364.938,136.687,350.194,121.943,332,121.921z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M375.24,79.281H228l-29.28-34.8c-0.985-1.184-2.461-1.848-4-1.8H76c-16.452,0.027-30.364,12.181-32.6,28.48h108.28
c5.678-0.014,11.069,2.492,14.72,6.84l25,29.72H332c26.005,0.044,47.076,21.115,47.12,47.12v167.52
c16.488-2.057,28.867-16.064,28.88-32.68v-177.48C407.957,94.1,393.34,79.413,375.24,79.281z" />
														</g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
													<g>
													</g>
												</svg>
											</div>

											<div class="overview--files">
												<ul>
													<li v-for="file in files" v-bind:key="file">
														{{ file.name }} ({{ file.size | kb }} kb) <button @click="removeFile(file)" title="Remove">x</button>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<hr />
									<div class="row row--spacing">
										<div class="col-xs-12 col-sm-12 col-lg-12">
											<button class="submit-button" v-bind:style="{
											background: page.secondaryColor,
											}" @click="submit()">
												Submit
											</button>
											<a href="https://contestcrate.com"><img src="/img/Powered.png" class="form--powered" /></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="countdown--block" v-if="contestEnded == true"><strong>This Contest Has Ended!</strong></div>
					</div>
					<div class="col-lg-3 col-12"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import Loading from "vue-loading-overlay";
	import "vue-loading-overlay/dist/vue-loading.css";
	//import FlipCountdown from 'vue2-flip-countdown'
	import 'material-icons/css/material-icons.scss';

	export default {
		name: "page",
		title: "Remix Contest",
		components: {
			Loading
		},
		data() {
			return {
				page_id: this.$route.params.id,
				enabled: 0,
				isLoading: false,
				fullPage: true,

				contestIsOpen: false,
				openingIn: null,
				closingIn: null,
				openingDate: null,
				contestEnded: false,

				demo: {},
				submitted: false,
				page: null,

				type: "soundcloud",
				files: []
			};
		},
		mounted() {
			var self = this;
			this.isLoading = true;

			axios({
					method: "GET",
					crossdomain: true,
					headers: {
						"Content-Type": "multipart/form-data"
					},
					url: "https://api.contestcrate.com/contest/show/" + self.page_id,
				})
				.then(function(response) {
					self.page = response.data;
					self.isLoading = false;
					self.openingDate = new Date(self.page.startDate * 1000).getFullYear() + '-' + (new Date(self.page.startDate * 1000).getMonth() + 1) + '-' + new Date(self.page.startDate * 1000).getDate() + ' 00:00:00';
					self.initiateCountdown();
					self.enabled = self.page.enabled;
					self.title = this.page.title + " | Remix Contest";
				})
				.catch(function(error) {
					self.errors = error.response.data;
					self.isLoading = false;
				});

		},
		methods: {
			downloadStems() {
				this.$ga.event({
					eventCategory: 'pages',
					eventAction: 'download',
					eventLabel: 'Download Stems',
					eventValue: 1
				});
				var self = this;
				setTimeout(function() {
					window.location = self.page.trackStems;
				}, 500);
			},
			submit() {
				var self = this;
				this.isLoading = true;

				var formData = new FormData();
				formData.append("track_name", this.page.trackArtist + " - " + this.page.trackName + "(" + this.demo.artist_name + " Remix)");
				formData.append("artist_name", this.demo.artist_name);
				formData.append("artist_email", this.demo.artist_email);

				if (this.type == "file") {
					formData.append('demo', this.files[0]);
				} else if (this.type == "soundcloud") {
					formData.append('soundcloud_url', this.demo.soundcloud_url);
				} else if (this.type == "dropbox") {
					formData.append('dropbox_url', this.demo.dropbox_url);
				}


				axios.post("https://api.contestcrate.com/contest/submit/" + self.page_id, formData)
					.then(function(result) {
						if (result.data.demo) {
							self.isLoading = false;
							self.submitted = true;
						} else {
							self.isLoading = false;

						}
					}, function(error) {
						self.errors = error.response.data;
						self.isLoading = false;
					});
			},
			initiateCountdown() {
				const milliseconds = this.page.startDate * 1000;
				var countDownDate = new Date(milliseconds).getTime();
				console.log("Countdown date: " + countDownDate);
				var self = this;
				var x = setInterval(function() {
					var now = new Date().getTime();

					var distance = countDownDate - now;

					var days = Math.floor(distance / (1000 * 60 * 60 * 24));
					var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
					var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
					var seconds = Math.floor((distance % (1000 * 60)) / 1000);
					self.openingIn = {
						"d": days,
						"h": hours,
						"m": minutes,
						"s": seconds
					};
					if (distance < 0) {
						clearInterval(x);
						self.initiateTimeLeft();
						self.contestIsOpen = true;
					}
				}, 1000);
			},
			initiateTimeLeft() {
				const milliseconds = this.page.endDate * 1000;
				var countDownDate = new Date(milliseconds).getTime();
				var self = this;
				var x = setInterval(function() {
					var now = new Date().getTime();
					var distance = countDownDate - now;
					var days = Math.floor(distance / (1000 * 60 * 60 * 24));
					var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
					var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
					var seconds = Math.floor((distance % (1000 * 60)) / 1000);
					self.closingIn = {
						"d": days,
						"h": hours,
						"m": minutes,
						"s": seconds
					};

					if (distance < 0) {
						clearInterval(x);
						self.contestIsOpen = false;
						self.contestEnded = true;
					}
				}, 1000);
			},
			addFile(e) {
				let droppedFiles = e.dataTransfer.files;
				if (!droppedFiles) return;
				([...droppedFiles]).forEach(f => {
					this.files.push(f);
				});
			},
			removeFile(file) {
				this.files = this.files.filter(f => {
					return f != file;
				});
			},
		},
	};
</script>
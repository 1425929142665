<template>
  <div class="home">
    <h1>
      Hi, Looks like you were trying to submit a demo to a label using
     <a href="https://contestcrate.com"> ContestCrate.com. </a>
    </h1>

    <h2>Maybe:</h2>
    <ul>
      <li>You misspelled the url. Please check if you're on the right url</li>
      <li>
        The label shared the wrong link or removed their page. Try to contact
        the music label you are trying to submit to.
      </li>
      <li>Something is wrong on our side. Please message us.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
